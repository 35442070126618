import { sectionStyle } from 'holded/lib/styles';
import { cleanDoubleSlash } from 'holded/lib/utils';
import { NavbarItem } from 'holded/modules/cms/domain/page';

type MenuFooterProps = {
  nav: NavbarItem;
  dark: boolean;
};

const MenuFooter = ({ nav, dark }: MenuFooterProps) => (
  <a href={'/' + cleanDoubleSlash(nav.attributes.footer.href)} className="flow-root w-full">
    <div className={`py-8 px-5 ${dark ? 'bg-gray-800 hover:bg-[#273243]' : 'bg-gray-light hover:bg-gray-soft'} `}>
      <div className="flex items-center">
        <div className={`text-base font-medium ${dark ? 'text-white' : sectionStyle.textColor.default}`}>
          {nav.attributes.footer.title}
        </div>
        {nav.attributes?.footer?.tagText && (
          <span className="ml-3 inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium leading-5 bg-blue-100 text-blue-dark">
            {nav.attributes.footer.tagText}
          </span>
        )}
      </div>
      <p className={`mt-1 text-sm ${sectionStyle.textColor.secondary}`}>{nav.attributes.footer.description}</p>
    </div>
  </a>
);

export default MenuFooter;
